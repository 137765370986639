import React from 'react'
import { Container, Breadcrumb } from 'react-bootstrap'

import { Helmet } from 'react-helmet'
import '../home.css'
import '../about.css'
import '../product.css'
import OptionsCalculator from '../../components/options-calculator'

const ContactUs = () => {
    return (
        <>
            <Helmet>
                <title>Options Calculator| Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Our Products | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="calculator-page">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className='page-title'> Options Calculator </div>
                        <Breadcrumb className='pt-1 mb-4'>
                            <Breadcrumb.Item active> Home </Breadcrumb.Item>
                            <Breadcrumb.Item active> Resources </Breadcrumb.Item>
                            <Breadcrumb.Item active> Options Calculator </Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='page-subtitle mb-4 pb-4'> This options calculator serves as a comprehensive tool tailored for traders to optimize their options strategies. It combines position sizing, risk/reward analysis, profit percentage, and return percentage calculations, all customized to the user’s account. By factoring in account size, risk tolerance, and trade specifics, it helps traders determine ideal position sizes, assess potential profits and losses, and make well-informed, data-driven trading decisions.</div>
                        <OptionsCalculator />
                    </Container>
                </div>
            </div>
        </>
    )
}

export default ContactUs
