import React, { useState } from 'react'
import { Container, Tab, Tabs, Card, Breadcrumb } from 'react-bootstrap'

import { Helmet } from 'react-helmet'
import '../../home.css'
import '../../about.css'
import '../../product.css'

import SymbolOverview from './SymbolOverview'
import AdvancedChart from './AdvancedChart'
import EconomicCalendar from './EconomicCalendar'
import StockHeatmap from './StockHeatmap'

const LiveMarketData = () => {
    const [SelectedTab, setSelectedTab] = useState('Symbol Overview')

    return (
        <>
            <Helmet>
                <title>Live Market Data | Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Live Market Data | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="product-page">
                <div className='bg-dark page-padding'>
                    <Container className='position-relative z-1'>
                        <div className='page-title'> Live Market Data </div>
                        <Breadcrumb className='pt-1 mb-4'>
                            <Breadcrumb.Item active> Home </Breadcrumb.Item>
                            <Breadcrumb.Item active> Resources </Breadcrumb.Item>
                            <Breadcrumb.Item active> Live Market Data </Breadcrumb.Item>
                            <Breadcrumb.Item active> {SelectedTab} </Breadcrumb.Item>
                        </Breadcrumb>
                        <h2>{SelectedTab}</h2>
                        <Card.Body className='bg-white text-dark p-0 rounded'>
                            <Tabs
                                activeKey={SelectedTab}
                                onSelect={(t) => setSelectedTab(t)}
                            >
                                <Tab eventKey="Symbol Overview" title="Symbol Overview" style={{ height: 'calc(100vh - 300px)', minHeight: 500, maxHeight: 800 }}>
                                    <SymbolOverview />
                                </Tab>
                                <Tab eventKey="Advanced Chart" title="Advanced Chart" style={{ height: 'calc(100vh - 300px)', minHeight: 500, maxHeight: 800 }}>
                                    <AdvancedChart />
                                </Tab>
                                <Tab eventKey="Economic Calendar" title="Economic Calendar" style={{ height: 'calc(100vh - 300px)', minHeight: 500, maxHeight: 800 }}>
                                    <EconomicCalendar />
                                </Tab>
                                <Tab eventKey="Stock Heatmap" title="Stock Heatmap" style={{ height: 'calc(100vh - 300px)', minHeight: 500, maxHeight: 800 }}>
                                    <StockHeatmap />
                                </Tab>
                            </Tabs>
                        </Card.Body>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default LiveMarketData