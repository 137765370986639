import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import { Row, Col, Container } from 'react-bootstrap';
import SaleImage from './../assets/hot-sale.png'
import Check from './../assets/Check.png'
import Cross from './../assets/Cross.png'

const PricingSection = () => {
    const [hoveredSection, setHoveredSection] = useState("");
    return (
        <>
            {/* <div class="MembershipHead">
                <h6>Products</h6>
                <p>Find the product That Fuels Your Trading Success  with Picco!</p>
            </div> */}
            <div className="MembershipBody">
                <div className="MembershipTitle">
                    <article />
                    <article>
                        <p>
                            Picco <br /> Trading Hub
                        </p>
                    </article>
                    <article>
                        <p>
                            alerts <br /> room
                        </p>
                    </article>
                    <article>
                        <p>
                            Futures Ai <br /> bot
                        </p>
                    </article>
                    <article>
                        <p>Package</p>
                    </article>
                </div>
                <div className="MembershipPlan MembershipPrice">
                    <article>
                        <p>Monthly Price</p>
                    </article>
                    <article>
                        <span>FREE </span>
                    </article>
                    <article>
                        <span>$149 </span>
                    </article>
                    <article>
                        <span>$299 </span>
                    </article>
                    <article>
                        <span>$399 </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Access to Community</p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Trading Challenges</p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Real-Time Scanners &amp; Charts</p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Live Commentary</p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Live </p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Expert Trade Guidance </p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Daily Plan with Key Levels </p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Long &amp; Short Trade Alerts </p>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>Balanced Risk &amp; Reward Strategy </p>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article>
                        <p>AI Automated Trading</p>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Check} />
                        </span>
                    </article>
                    <article>
                        <span>
                            <img src={Cross} />
                        </span>
                    </article>
                </div>
                <div className="MembershipPlan">
                    <article />
                    <article>
                        <Link to={{ pathname: 'https://portal.piccotrading.com' }} target="_blank">
                            <button onMouseEnter={() => setHoveredSection("Picco Hub")}
                                onMouseLeave={() => setHoveredSection("")} >Subscribe Now</button>
                        </Link>
                    </article>
                    <article>
                        <Link to={{ pathname: 'https://portal.piccotrading.com' }} target="_blank">
                            <button onMouseEnter={() => setHoveredSection("Alerts Room")}
                                onMouseLeave={() => setHoveredSection("")}>Subscribe Now</button>
                        </Link>
                    </article>
                    <article>
                        <Link to={{ pathname: 'https://portal.piccotrading.com' }} target="_blank">
                            <button onMouseEnter={() => setHoveredSection("AI Bot")}
                                onMouseLeave={() => setHoveredSection("")}>Subscribe Now</button>
                        </Link>
                    </article>
                    <article>
                        <Link to={{ pathname: 'https://portal.piccotrading.com' }} target="_blank">
                            <button onMouseEnter={() => setHoveredSection("Package")}
                                onMouseLeave={() => setHoveredSection("")}>Subscribe Now</button>
                        </Link>
                    </article>
                </div>
            </div>

            <div className="MembershipFooter">
                <Row>
                    <Col md={6}>
                        <div className="Description active h-100">
                            <h6>
                                <span>Picco Hub</span>
                            </h6>
                            <p>
                                Picco Hub is your go-to free trading forum, connecting traders of
                                all levels with real-time scanners, live charts, and expert trade
                                guidance during market hours. Learn and grow with risk management
                                strategies and technical analysis insights from veteran traders.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="Description active h-100">
                            <h6>
                                <span>Alerts Room</span>
                            </h6>
                            <p>
                                Stay ahead with our all-in-one trading alerts. Get timely options
                                and stock trade ideas, plus futures alerts with clear signals,
                                daily plans, and live chat support for confident, profitable
                                trading across markets.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={6}>
                        <div className="Description active h-100">
                            <h6>
                                <span>AI Bot</span>
                            </h6>
                            <p>
                                Our unique trading algorithm harnesses advanced pattern
                                recognition to execute high-potential trades in real time with
                                exceptional efficiency. Built for minimal oversight, it’s
                                engineered to optimize strategies and drive long-term growth,
                                setting a new standard in algorithmic trading.
                            </p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="Description active h-100">
                            <h6>
                                <span>Package</span>
                            </h6>
                            <p>
                                Get the best of both worlds with our comprehensive Trading
                                Package—featuring an automated bot for hands-free execution and
                                2-5 daily trade ideas for stocks, options, and futures. This
                                package combines expert strategies with consistent results to
                                elevate your trading game.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>

            {/* <div className="MembershipFooter">
                <div
                    className={`Description ${hoveredSection === "Picco Hub" ? "active" : "hidden"
                        }`}
                >
                    <h6>
                        <span>Picco Hub</span>
                    </h6>
                    <p>
                        Picco Hub is your go-to free trading forum, connecting traders of
                        all levels with real-time scanners, live charts, and expert trade
                        guidance during market hours. Learn and grow with risk management
                        strategies and technical analysis insights from veteran traders.
                    </p>
                </div>
                <div
                    className={`Description ${hoveredSection === "Alerts Room" ? "active" : "hidden"
                        }`}
                >
                    <h6>
                        <span>Alerts Room</span>
                    </h6>
                    <p>
                        Stay ahead with our all-in-one trading alerts. Get timely options
                        and stock trade ideas, plus futures alerts with clear signals,
                        daily plans, and live chat support for confident, profitable
                        trading across markets.
                    </p>
                </div>
                <div
                    className={`Description ${hoveredSection === "AI Bot" ? "active" : "hidden"
                        }`}
                >
                    <h6>
                        <span>AI Bot</span>
                    </h6>
                    <p>
                        Our unique trading algorithm harnesses advanced pattern
                        recognition to execute high-potential trades in real time with
                        exceptional efficiency. Built for minimal oversight, it’s
                        engineered to optimize strategies and drive long-term growth,
                        setting a new standard in algorithmic trading.
                    </p>
                </div>
                <div
                    className={`Description ${hoveredSection === "Package" ? "active" : "hidden"
                        }`}
                >
                    <h6>
                        <span>Package</span>
                    </h6>
                    <p>
                        Get the best of both worlds with our comprehensive Trading
                        Package—featuring an automated bot for hands-free execution and
                        2-5 daily trade ideas for stocks, options, and futures. This
                        package combines expert strategies with consistent results to
                        elevate your trading game.
                    </p>
                </div>
            </div> */}
        </>
    )

};

export default PricingSection;
