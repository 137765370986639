import React from 'react'
import { Container, Breadcrumb } from 'react-bootstrap'

import { Helmet } from 'react-helmet'
import '../home.css'
import '../about.css'
import '../product.css'
import ConsistencyCalculator from '../../components/consistency-calculator.js.js'

const ContactUs = () => {
    return (
        <>
            <Helmet>
                <title>Consistency Calculator| Picco</title>
                <meta name="description" content="Description of the website" />
                <meta property="og:title" content="Our Products | Picco" />
                <meta property="og:description" content="Description of the website" />
            </Helmet>

            <div className="calculator-page">
                <div className='bg-dark page-padding'>
                    <Container>
                        <div className='page-title'> Consistency Calculator </div>
                        <Breadcrumb className='pt-1 mb-4'>
                            <Breadcrumb.Item active> Home </Breadcrumb.Item>
                            <Breadcrumb.Item active> Resources </Breadcrumb.Item>
                            <Breadcrumb.Item active> Consistency Calculator </Breadcrumb.Item>
                        </Breadcrumb>
                        <div className='page-subtitle position-relative mb-4 pb-4'> This consistency calculator is a valuable resource designed specifically for traders working with prop firms, helping them adhere to the Consistency Rule. Simply input your numbers into this calculator, and you will instantly see the results, making it easier to stay on track.</div>
                        <div className='calculator-title'> How Does the Consistency Rule Work? </div>
                        <div className='page-subtitle position-relative mb-4 pb-4'> A single day’s profit cannot exceed a set percentage of total profits accumulated up to the payout request date, typically around 35% or 20%, depending on the prop firm. After a payout, the percentage resets for the next period.</div>
                        <ConsistencyCalculator />
                    </Container>
                </div>
            </div >
        </>
    )
}

export default ContactUs
