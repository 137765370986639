import React, { useState, useEffect } from 'react';

const OptionsCalculator = () => {
    const [Accounts, setAccounts] = useState([{ AccountSize: '', Contract: '', Max: '', DollarAmount: '' }]);
    const [AccountSize, setAccountSize] = useState("");
    const [Average, setAverage] = useState('');
    const [GainAverage, setGainAverage] = useState('');
    const [MaxLoss, setMaxLoss] = useState('');
    const [MaxSell, setMaxSell] = useState('');
    const [Entry, setEntry] = useState('');
    const [Sell, setSell] = useState('');
    const [ContractPrice, setContractPrice] = useState('');
    const [Return, setReturn] = useState('');
    const [ProfitLoss, setProfitLoss] = useState('');
    const [Profit, setProfit] = useState('');
    const [Contracts, setContracts] = useState('');
    const [AvgSell, setAvgSell] = useState('');
    const [TotalProfit, setTotalProfit] = useState('');
    const loss = 0.85;
    const gainValue = 1.2;

    useEffect(() => {
        calculateState();
    }, [Accounts]);

    const calculateState = () => {
        const validAccounts = Accounts.filter((account) => account.AccountSize > 0 && account.Contract > 0);
        if (validAccounts.length > 0) {
            const totalContracts = validAccounts.length;
            const avgSell = validAccounts.length > 0 ? (validAccounts.reduce((sum, account) => sum + account.Contract, 0) / validAccounts.length) : '';
            const totalProfit = totalContracts * avgSell;
            setContracts(totalContracts);
            setAvgSell(`${+avgSell.toFixed(2)}`);
            setTotalProfit(`${+totalProfit.toFixed(2)}`);
        }
        else {
            setContracts('')
            setAvgSell('')
            setTotalProfit('')
        }
    };

    const addProfitField = () => {
        setAccounts((prev) => [
            ...prev,
            { AccountSize: AccountSize, Contract: "", Max: "", DollarAmount: "" },
        ]);
    };

    useEffect(() => {
        calculateLoss()
    }, [Average])

    useEffect(() => {
        calculateSell()
    }, [GainAverage])


    const calculateLoss = () => {
        if (!Average) {
            calculateSell('');
            return;
        }
        setMaxLoss(`${+(loss * Average).toFixed(2)}`)
    };

    const calculateSell = () => {
        if (!GainAverage) {
            setProfitLoss('');
            return;
        }
        setMaxSell(`${+(gainValue * GainAverage).toFixed(2)}`)
    };

    useEffect(() => {
        calculateProfitLoss();
    }, [Entry, Sell]);

    const calculateProfitLoss = () => {
        if (!Entry || !Sell) {
            setProfitLoss('');
            return;
        }
        const profitOrLoss = ((Sell - Entry) / Entry) * 100;
        setProfitLoss(`${+profitOrLoss.toFixed(2)}%`);
    };

    useEffect(() => {
        calculateProfit();
    }, [ContractPrice, Return]);

    const calculateProfit = () => {
        if (!ContractPrice || !Return) return 0;
        setProfit((ContractPrice * Return / 100));
    };

    const removeProfitField = (index) => {
        if (Accounts.length > 1) {
            setAccounts((prev) => prev.filter((_, i) => i !== index));
        }
    };

    const updateProfitField = (index, field, value) => {
        setAccounts((prev) =>
            prev.map((item, i) => {
                if (i === index) {
                    const updatedItem = { ...item, [field]: value === "" ? "" : Number(value) };
                    const { Contract } = updatedItem;
                    if (Contract !== "" && AccountSize !== "" && AccountSize > 0 && Contract > 0) {
                        const maxPosition = 13;
                        const Shares = (AccountSize * (maxPosition / 100)) / Contract;
                        const Max = Shares / 100;
                        const DollarAmount = Contract * 100 * Max;

                        updatedItem.Max = +Max.toFixed(2);
                        updatedItem.DollarAmount = +DollarAmount.toFixed(2);
                    } else {
                        updatedItem.Max = "";
                        updatedItem.DollarAmount = "";
                    }
                    return updatedItem;
                }
                return item;
            })
        );
    };

    const handleAccountSizeChange = (value) => {
        setAccountSize(value);
        setAccounts((prev) =>
            prev.map((item) => ({ ...item, AccountSize: value === "" ? "" : Number(value) }))
        );
    };

    return (
        <>
            <div className="MembershipArea">

                <div className="OptionsArea">
                    <h3>Options Calculator</h3>
                    <p>Manage Risk (no more then 1/8 on any trade)</p>
                    <div className="OptionsBox">
                        <div className="OptionsLeft">
                            <ul>
                                <li>
                                    <strong>Account Size</strong>
                                </li>
                                <li>
                                    <strong>Contract</strong>
                                </li>
                                <li>
                                    <strong>Max #</strong>
                                </li>
                                <li>
                                    <strong>Dollar Amount</strong>
                                </li>
                                <li />
                            </ul>
                            {Accounts.map((account, index) => (
                                <ul key={index}>
                                    <li>
                                        <input
                                            type="number"
                                            value={account.AccountSize}
                                            onChange={(e) => handleAccountSizeChange(e.target.value)}
                                            disabled={index !== 0}

                                        />
                                    </li>
                                    <li>
                                        <input
                                            type="number"
                                            value={account.Contract}
                                            onChange={(e) =>
                                                updateProfitField(index, "Contract", e.target.value)
                                            }
                                        />
                                    </li>
                                    <li>

                                        <span>{account?.Max}</span>
                                    </li>
                                    <li>
                                        <span>{account?.DollarAmount}</span>
                                    </li>
                                    <li>
                                        {Accounts.length - 1 === index && (
                                            <a className="cursor-pointer" onClick={addProfitField}>
                                                <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.779 22C18.629 22 23.3714 17.5228 23.3714 12C23.3714 6.47715 18.629 2 12.779 2C6.92893 2 2.18652 6.47715 2.18652 12C2.18652 17.5228 6.92893 22 12.779 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M12.7791 8V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M8.54199 12H17.016" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </a>
                                        )}
                                        {
                                            Accounts.length > 1 && (
                                                <a className="cursor-pointer" onClick={() => removeProfitField(index)}>
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.779 22C18.629 22 23.3714 17.5228 23.3714 12C23.3714 6.47715 18.629 2 12.779 2C6.92893 2 2.18652 6.47715 2.18652 12C2.18652 17.5228 6.92893 22 12.779 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M8.54199 12H17.016" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </a>
                                            )}
                                    </li>
                                </ul>
                            ))}
                        </div>
                        <div className="OptionsRight">
                            <div className="OptionsRisk">
                                <h4>
                                    <span>Risk/ Reward Calculator</span>
                                </h4>
                                <article>
                                    <aside>
                                        <h6>Average</h6>
                                        <p><input
                                            type="number"
                                            value={Average}
                                            onChange={(e) => setAverage(e.target.value)}
                                        /></p>
                                    </aside>
                                    <aside>
                                        <h6>15% Max Loss</h6>
                                        <p>{loss}</p>
                                    </aside>
                                    <aside>
                                        <h6>Max Loss</h6>
                                        <p>{MaxLoss}</p>
                                    </aside>
                                </article>
                                <article>
                                    <aside>
                                        <h6>Average</h6>
                                        <p><input
                                            type="number"
                                            value={GainAverage}
                                            onChange={(e) => setGainAverage(e.target.value)}
                                        /></p>
                                    </aside>
                                    <aside>
                                        <h6>
                                            20% Gain <span>(Start Scaling)</span>
                                        </h6>
                                        <p>{gainValue}</p>
                                    </aside>
                                    <aside>
                                        <h6>Sell Target</h6>
                                        <p>{MaxSell}</p>
                                    </aside>
                                </article>
                            </div>
                            <div className="OptionsRisk">
                                <h4>
                                    <span>Profit % Calculator</span>
                                </h4>
                                <article>
                                    <aside>
                                        <h6>Entry</h6>
                                        <p> <input
                                            type="number"
                                            value={Entry}
                                            onChange={(e) => setEntry(e.target.value)}
                                        /></p>
                                    </aside>
                                    <aside>
                                        <h6>Sell</h6>
                                        <p> <input
                                            type="number"
                                            value={Sell}
                                            onChange={(e) => setSell(e.target.value)}

                                        /></p>
                                    </aside>
                                    <aside>
                                        <h6>% Profit/Loss</h6>
                                        <p>{ProfitLoss}</p>
                                    </aside>
                                </article>
                            </div>
                            <div className="OptionsRisk">
                                <h4>
                                    <span>Amount Made</span>
                                </h4>
                                <article>
                                    <aside>
                                        <h6>Contract Price</h6>
                                        <p> <input
                                            type="number"
                                            value={ContractPrice}
                                            onChange={(e) => setContractPrice(e.target.value)}
                                        /></p>
                                    </aside>
                                    <aside>
                                        <h6>Return %</h6>
                                        <p> <input
                                            type="number"
                                            value={Return}
                                            onChange={(e) => setReturn(e.target.value)}
                                        /></p>
                                    </aside>
                                    <aside>
                                        <h6>Profit</h6>
                                        <p>{Profit}</p>
                                    </aside>
                                </article>
                            </div>
                            {/* <div className="OptionsRisk">
                                <h4>
                                    <span>How much money did I make?</span>
                                </h4>
                                <article>
                                    <aside>
                                        <h6># of Contracts</h6>
                                        <p>{Contracts}</p>
                                    </aside>
                                    <aside>
                                        <h6>Avg Sell</h6>
                                        <p>{AvgSell}</p>
                                    </aside>
                                    <aside>
                                        <h6>Total Profit</h6>
                                        <p>{TotalProfit}</p>
                                    </aside>
                                </article>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

};

export default OptionsCalculator;
