
import React, { useState, useEffect } from "react";

const ConsistencyCalculator = () => {
    const [Profit, setProfit] = useState([""]);
    const [totalProfit, setTotalProfit] = useState("");
    const [consistencyRule, setConsistencyRule] = useState(0.2);
    const [MostProfit, setMostProfit] = useState("");
    const [maxProfit, setMaxProfit] = useState("");
    const [MeetsConsistency, setMeetsConsistency] = useState("")
    const [AccountBalance, setAccountBalance] = useState("");
    const [Wins, setWins] = useState("");
    const [Losse, setLosse] = useState("");
    const [WinPercentage, setWinPercentage] = useState("");

    useEffect(() => {
        calculateState()
    }, [Profit, consistencyRule]);

    const calculateState = () => {
        const profit = Profit.filter(value => value !== "");
        if (profit.length > 0) {
            const sum = profit.reduce((acc, curr) => acc + Number(curr), 0);
            const mostProfit = sum * consistencyRule
            const maxProfit = Math.max(...profit)
            const accountBalence = maxProfit / consistencyRule

            setTotalProfit(`$${sum}`);
            setMostProfit(`$${+mostProfit.toFixed(2)}`)
            setMaxProfit(`$${+maxProfit.toFixed(2)}`)
            setMeetsConsistency(maxProfit < mostProfit ? 'Yes' : 'No')

            setAccountBalance(accountBalence)

            const winsCount = () => {
                return profit.filter(value => value >= 0).length;
            };
            setWins(winsCount)

            const lossesCount = () => {
                return profit.filter(value => value < 0).length;
            };
            setLosse(lossesCount)

            const totalCount = Wins + Losse;
            const winPercentage = totalCount ? +(Wins / totalCount * 100).toFixed(2) : 0;
            setWinPercentage(`${winPercentage}%`);
        } else {
            setTotalProfit("");
            setMostProfit("")
            setMaxProfit("")
            setMeetsConsistency("")
            setAccountBalance("")
            setWins("")
            setLosse("")
            setWinPercentage("")
        }
    }

    const addProfitField = () => {
        setProfit((prev) => [...prev, '']);
    };

    const removeProfitField = (index) => {
        if (Profit.length > 1) {
            setProfit((prev) => prev.filter((_, i) => i !== index));
        }
    };

    const updateProfit = (index, value) => {
        setProfit((prev) =>
            prev.map((item, i) => (i === index ? (value === "" ? "" : Number(value)) : item))
        );
    };

    return (
        <>
            <div className="MembershipArea">
                <div className="CalculatorArea">
                    <h3>Consistency Rule Calculator</h3>
                    <div className="CalculatorBox">
                        <div className="CalculatorLeft">
                            <ul>
                                <li>
                                    <strong>Date/Days</strong>
                                </li>
                                <li>
                                    <strong>EOD Profit $</strong>
                                </li>
                                <li />
                            </ul>
                            {Profit.map((value, index) => (
                                <ul key={index}>
                                    <li>
                                        <span>Day {index + 1}</span>
                                    </li>
                                    <li>
                                        <input
                                            type="number"
                                            value={value}
                                            onChange={(e) => updateProfit(index, e.target.value)}
                                        />
                                    </li>
                                    {
                                        Profit.length - 1 == index && (
                                            <li>
                                                <a className="cursor-pointer" onClick={addProfitField}>
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.779 22C18.629 22 23.3714 17.5228 23.3714 12C23.3714 6.47715 18.629 2 12.779 2C6.92893 2 2.18652 6.47715 2.18652 12C2.18652 17.5228 6.92893 22 12.779 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M12.7791 8V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8.54199 12H17.016" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </a>
                                            </li>
                                        )}
                                    {
                                        Profit.length > 1 && (
                                            <li>
                                                <a className="cursor-pointer" onClick={() => removeProfitField(index)}>
                                                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12.779 22C18.629 22 23.3714 17.5228 23.3714 12C23.3714 6.47715 18.629 2 12.779 2C6.92893 2 2.18652 6.47715 2.18652 12C2.18652 17.5228 6.92893 22 12.779 22Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        <path d="M8.54199 12H17.016" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </a>
                                            </li>
                                        )}
                                </ul>
                            ))}
                        </div>
                        <div className="CalculatorRight">
                            <aside>
                                <label>Account Balance</label>
                                <span>{totalProfit}</span>
                            </aside>
                            <aside>
                                <label>Consistency Rule Option</label>
                                <select
                                    className="cursor-pointer"
                                    value={consistencyRule}
                                    onChange={(e) => setConsistencyRule(Number(e.target.value))}>
                                    <option value={0.2}>0.2</option>
                                    <option value={0.3}>0.3</option>
                                    <option value={0.4}>0.4</option>
                                    <option value={0.5}>0.5</option>
                                    <option value={0.6}>0.6</option>
                                    <option value={0.7}>0.7</option>
                                </select>

                            </aside>
                            <aside>
                                <label>Most Profits in A Day Must Be (Less Than)</label>
                                <span>{MostProfit}</span>
                            </aside>
                            <aside>
                                <label>Highest Profit in a Day</label>
                                <span>{maxProfit}</span>
                            </aside>
                            <aside>
                                <label>Most Consistency</label>
                                <span>{MeetsConsistency}</span>
                            </aside>
                            <aside>
                                <label>
                                    How Much Does the Account Balance Need To Be To Meet The Rule?
                                </label>
                                <span>{AccountBalance}</span>
                            </aside>
                            <aside>
                                <label>Wins</label>
                                <span>{Wins}</span>
                            </aside>
                            <aside>
                                <label>Losses</label>
                                <span>{Losse}</span>
                            </aside>
                            <aside>
                                <label>Win Rate%</label>
                                <span>{WinPercentage}</span>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConsistencyCalculator;